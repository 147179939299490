import React from 'react';
import Layout from "../components/layout";
import {UserContext} from "@cg-squad/ui-components";
/*import Interests from "../components/interests";*/

const Account = (props) => {

    return (
        <Layout>
            <UserContext.Consumer>
                {({user, updateState}) => (
                    <main className="wrapper">
                        {user && <div className={"mb-4"}>
                            <div className={"flex"}>
                                <div className={"flex-1"}>
                                    {/*{user.avatar && <Avatar style={{
                                        width: '200px',
                                        height: '200px',
                                        margin: 'auto'
                                    }} {...user.avatar}/>}*/}
                                    <div className={"-mt-8 text-center cursor-pointer"}
                                         onClick={() => {
                                             updateState('openAvatarDialog', true)
                                         }}>
                                        Change Avatar
                                    </div>
                                </div>
                                <div className={"flex-1 flex-grow-2"}>
                                    <div>{user.name}</div>
                                    <div>{user.email}</div>
                                    <div>
                                        <div className={"text-xl font-semibold"}>Interests</div>
                                        {/*<div>
                                            <Interests selected={user.interests} readOnly={true}/>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </main>
                )}
            </UserContext.Consumer>
        </Layout>
    )
}

export default Account
